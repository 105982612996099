import axios from 'axios';

let baseUrl = "http://api.tumark.site/";

if(document.domain == 'app.tumarketing3.com'){
    baseUrl = "https://api.tumarketing3.com";
}

if(document.domain == 'apk.tumarketing3.com'){
    baseUrl = "https://api.tumarketing3.com";
}

if(document.domain == 'apt.tumarketing3.com'){
    baseUrl = "https://api.tumarketing3.com";
}

if(document.domain == 'kam.tumarketing3.com'){
    baseUrl = "https://api.tumarketing3.com";
}

if(document.domain == 'app.tmkt3.com' || document.domain == 'kam.tmkt3.com' || document.domain == 'adm.tmkt3.com' || document.domain == 'admin.tmkt3.com'){
    baseUrl = "https://api.tmkt3.com";
}

// baseUrl = "https://api.tumarketing3.com";

axios.defaults.baseURL = baseUrl;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'Application/json';

if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'undefined') {
    axios.defaults.headers.common['access-token'] = '';
    axios.defaults.headers.common['api-token'] = '';
} else {
    axios.defaults.headers.common['access-token'] = localStorage.getItem('token');
    axios.defaults.headers.common['api-token'] = localStorage.getItem('token');
}

axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if(error.response !== undefined){
        if (error.response.status === 401) {
            console.log(error.response);
        } else if (error.response.status === 500 || error.response.status === 404 || error.response.status === 400) {
            throw error;
        } else {
            return Promise.reject(error);
        }
    }
    else{
        return Promise.reject(error);
    }
})
